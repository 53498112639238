import * as React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const PostTagMap = new Map<string, { name: string; color: string }>([
  ['energy', { name: 'EN', color: 'orange' }],
  ['environment', { name: 'OŚ', color: 'green' }],
  ['bhp', { name: 'BHP', color: 'blue' }],
  ['others', { name: 'INNE', color: 'gray' }],
]);

const CurrentNews = ({
  topicalities,
  onEditRowClick,
  isTopicalitiesEditable,
  onDeleteRowClick,
  getTopicalities,
  showCoupled,
  infiniteScroll = false,
}: P) => {
  const observer = React.useRef<IntersectionObserver | null>(null);

  const lastPostRef = React.useCallback(
    (node: HTMLElement | null) => {
      if (!infiniteScroll || topicalities.page >= topicalities.lastPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          getTopicalities(topicalities.page + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [infiniteScroll, topicalities.page, topicalities.lastPage, getTopicalities],
  );

  return (
    <div className={`currentNews ${showCoupled ? 'coupled' : 'columnCount'}`}>
      {showCoupled ? (
        <>
          <div className="column">
            {topicalities.data.slice(0, 3).map((post, idx) => (
              <Post
                post={post}
                key={`column1-${idx}`}
                onEditRowClick={onEditRowClick}
                onDeleteRowClick={onDeleteRowClick}
                isTopicalitiesEditable={isTopicalitiesEditable}
              />
            ))}
          </div>
          <div className="column">
            {topicalities.data.slice(3).map((post, idx) => (
              <Post
                post={post}
                key={`column2-${idx}`}
                onEditRowClick={onEditRowClick}
                onDeleteRowClick={onDeleteRowClick}
                isTopicalitiesEditable={isTopicalitiesEditable}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="column">
            {topicalities.data
              .filter((_, idx) => idx % 2 === 0)
              .map((post, idx, array) => (
                <Post
                  post={post}
                  key={`column1-${idx}`}
                  onEditRowClick={onEditRowClick}
                  onDeleteRowClick={onDeleteRowClick}
                  isTopicalitiesEditable={isTopicalitiesEditable}
                  ref={array[array.length - 1] === post ? lastPostRef : null}
                />
              ))}
          </div>
          <div className="column">
            {topicalities.data
              .filter((_, idx) => idx % 2 !== 0)
              .map((post, idx, array) => (
                <Post
                  post={post}
                  key={`column2-${idx}`}
                  onEditRowClick={onEditRowClick}
                  onDeleteRowClick={onDeleteRowClick}
                  isTopicalitiesEditable={isTopicalitiesEditable}
                  ref={array[array.length - 1] === post ? lastPostRef : null}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

type P = {
  topicalities: {
    data: Array<any>;
    lastPage: number;
    page: number;
  };
  onEditRowClick: (topicalityData) => void;
  onDeleteRowClick: (topicalityData) => void;
  getTopicalities: (page?: number) => void;
  isTopicalitiesEditable: boolean;
  showCoupled?: boolean;
  infiniteScroll?: boolean;
};

const Post = React.forwardRef(({ post, isTopicalitiesEditable, onEditRowClick, onDeleteRowClick }: any, ref: React.Ref<HTMLDivElement>) => {
  const tag = PostTagMap.get(post.category);
  const handleClickLink = () => {
    if (post.link) {
      const hasProtocol = post.link.startsWith('http://') || post.link.startsWith('https://');
      const url = hasProtocol ? post.link : `https://${post.link}`;
      window.open(url, '_blank');
    }
  };
  return (
    <div className="currentPost" ref={ref}>
      <div className="currentPost__title">
        <div className={`link ${post.link ? 'link--active' : ''}`} onClick={() => handleClickLink()}>
          <div className="currentPost__tag_wrapper">
            <div className={`currentPost__tag currentPost__tag--${tag.color}`}>{tag.name}</div>
          </div>
          <div className="currentPost__title">{post.content}</div>
        </div>
        <p></p>
      </div>
      {isTopicalitiesEditable && (
        <div className="currentPost__actions">
          <button className="currentPost__editPost" onClick={() => onEditRowClick(post)}>
            <SVG type={SVG_TYPE.EDIT} />
          </button>
          <div
            className="currentPost__deletePost"
            onClick={async () => {
              await onDeleteRowClick(post);
            }}>
            <SVG type={SVG_TYPE.DELETE} />
          </div>
        </div>
      )}
    </div>
  );
});

Post.displayName = 'Post';

export default CurrentNews;
