import { Connector } from 'Modules/KnowledgeModule';
import { Knowledge } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Knowledge.GET_KNOWLEDGE_POSTS_INIT,
});

const success = (knowledgePosts: any) => {
  const postsWithImages = knowledgePosts.map(post => ({
    ...post,
    featuredImage: post._embedded?.['wp:featuredmedia']?.[0]?.source_url || null,
  }));


  return {
    type: Knowledge.GET_KNOWLEDGE_POSTS_SUCCESS,
    payload: {
      knowledgePosts: postsWithImages,
    },
  };
};



const getKnowledgePosts = (page: number) => {
  return async (dispatch) => {
    if(page===1) {
      dispatch(init())
    }
    try {
      const { data: knowledgePosts } = await Connector.getKnowledgePosts(page);
      console.log(knowledgePosts)
      dispatch(success(knowledgePosts));
      return knowledgePosts
    } catch (err) {
      return []
    }
  };
};

export default getKnowledgePosts;
