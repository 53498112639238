import * as React from 'react';
import { SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';

type P = {
  value: string;
  starred?: boolean;
  source?: boolean;
  onSourceClick?: () => void;
};

const LabelForm = ({ value, starred, source, onSourceClick }: P) => {
  return (
    <div className="areaManagement__label">
      {starred && <div className="areaManagement__star">☆</div>}
      {value}
      {source && (
        <div className={`areaManagement__source ${onSourceClick ? 'hover' : ''}`} onClick={onSourceClick}>
          + PDF <SVG type={SVG_TYPE.SOURCE} />
        </div>
      )}
    </div>
  );
};

export default LabelForm;
