import moment from 'moment';
import * as React from 'react';
import Select from 'react-select';
import { Cells, HighlightText, ReactTable, NewRow, DemoRow } from 'Shared/components';
import { getArea, getFiltersInLocal, saveFiltersInLocal } from 'Shared/utils';
import { USER_LEVELS } from 'Shared/enums';
import ReactTooltip from 'react-tooltip';

const prevFilter = {};

const filterMethod = (filter, row, setColumnFilterTerms) => {
  if (prevFilter[filter.id] !== filter.value) {
    prevFilter[filter.id] = filter.value;
    setColumnFilterTerms({ [filter.id]: filter.value?.toLowerCase() });
  }
  return row[filter.id]?.toLowerCase().includes(filter.value?.toLowerCase());
};

const tooltipActiveUser = (isOnline) => {
  if (isOnline) {
    return (
      <div className="tooltipLastActivity">
        <span className="tooltipLastActivity__icon" />
        <span className="tooltipLastActivity__light">Online</span>
      </div>
    );
  }
};
const tooltipLastActivity = (lastActivity) => {
  if (lastActivity) {
    return (
      <div className="tooltipLastActivity">
        <span className="tooltipLastActivity__light">ostatnio online:</span>
        <span className="tooltipLastActivity__bold">{lastActivity}</span>
      </div>
    );
  }
};

const getLatestDeadline = (tasks) => {
  if (!tasks || tasks.length === 0) {
    return null;
  }
  const formattedDates = tasks
    .map((task) => {
      const date = moment(task.deadline, ['YYYYMMDD', moment.ISO_8601], true);
      const formattedDate = date.isValid() ? date.format('YYYYMMDD') : null;
      return formattedDate;
    })
    .filter((date) => date !== null);

  if (formattedDates.length === 0) {
    return null;
  }
  const latest = formattedDates.reduce((latest, current) => (current > latest ? current : latest), '00000000');
  return latest;
};

const getColumns = (
  history,
  onEditClick,
  isCustomerLogged,
  userLevel,
  manageRow,
  areHiddenRowsShown,
  onDeleteRowClick,
  onCopyClick,
  isEditorOrSuperAdmin,
  // termOptions,
  departmentOptions,
  // filterByExecutiveAct,
  // filterByLegalBasis,
  // filterByReleasedFromResponsibility,
  // filterByOrganizationType,
  compendiumsActiveCompany,
  // filterByRegulatoryOrgan,
  area,
  filterByRating,
  // usersInCompanyOptions,
  filters,
  isCustomerAreaManager,
  setColumnFilterTerms,
  getCounters,
) => [
  {
    id: 'edit',
    accessor: 'edit',
    width: 30,
    headerClassName: 'sticky sticky--header sticky--hidden',
    className: 'sticky sticky--cell cell--noPadding cell--white',
    show: (isEditorOrSuperAdmin && !compendiumsActiveCompany) || isCustomerAreaManager,
    filterable: false,
    /* eslint-disable */
    Cell: ({ value, original }) =>
      isEditorOrSuperAdmin || (isCustomerAreaManager && original.company_id) ? (
        <Cells.Edit value={value} original={original} onClick={onEditClick} onDeleteClick={onDeleteRowClick} onCopyClick={onCopyClick} />
      ) : (
        <Cells.Edit value={value} original={original} onCopyClick={onCopyClick} />
      ),
    /* eslint-enable */
  },
  {
    id: 'department',
    accessor: 'department',
    Header: 'Dział',
    style: { fontWeight: '700' },
    width: 200,
    headerClassName: `sticky sticky--header ${isEditorOrSuperAdmin && !compendiumsActiveCompany ? 'sticky--20' : 'sticky--0'} sticky--header_last`,
    className: `sticky sticky--cell ${isEditorOrSuperAdmin && !compendiumsActiveCompany ? 'sticky--20' : 'sticky--0'} sticky--last`,
    /* eslint-disable */
    filterMethod: ({ value, id }, row) => {
      if (value && value.length) {
        return value.some((item) => row[id].toLowerCase().indexOf(item.value.toLowerCase()) > -1);
      } else {
        return true;
      }
    },
    Filter: ({ filter, onChange, column: { id } }) => {
      // console.log('filtry', filters);
      !filter && onChange(filters.hasOwnProperty(id) ? filters[id] : null);
      return (
        <Select
          value={filter ? filter.value : ''}
          style={{ width: '100%' }}
          placeholder="Wszystkie"
          isMulti
          onChange={(value) => {
            onChange(value);
            filters[id] = value;
            saveFiltersInLocal({ ...filters, area });
            console.log('zapisuje filtry');
          }}
          options={departmentOptions}
          classNamePrefix="multi-filter react-select"
        />
      );
    },
    Cell: ({ value, original }) => (
      <>
        <div className="badge-wrapper">
          <NewRow created_at={original.created_at} />
          <DemoRow isVisible={isEditorOrSuperAdmin && original.visible_for_demo} />
        </div>
        <Cells.ExtendedView
          isCustomerLogged={!isEditorOrSuperAdmin || compendiumsActiveCompany}
          userLevel={userLevel}
          value={value}
          original={original}
          history={history}
          area={area}
          highlight
        />
      </>
    ),
    /* eslint-enable */
  },
  {
    id: 'term',
    accessor: 'term',
    Header: 'Pojęcie',
    style: { fontWeight: '700' },
    width: 200,
    // headerClassName: `sticky sticky--header sticky--header_last ${isEditorOrSuperAdmin && !compendiumsActiveCompany ? 'sticky--220' : 'sticky--200'}`,
    // className: `sticky ${isEditorOrSuperAdmin && !compendiumsActiveCompany ? 'sticky--220' : 'sticky--200'} sticky--last sticky--cell`,

    // /* eslint-disable */
    // Cell: ({ value }) => <HighlightText text={value} />,
    // /* eslint-enable */

    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    /* eslint-disable */
    Cell: ({ value, column }) => <Cells.ExtendText value={value} columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'legal_basis',
    accessor: 'legal_basis',
    Header: 'GŁÓWNA PODSTAWA PRAWNA',
    style: { fontWeight: '700' },
    width: 450,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'executive_act',
    accessor: 'executive_act',
    Header: 'AKT WYKONAWCZY',
    style: { fontWeight: '700' },
    width: 450,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'legal_requirements',
    accessor: 'legal_requirements',
    Header: 'WYMAGANIE PRAWNE',
    width: 450,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'organization_responsibilities',
    accessor: 'organization_responsibilities',
    Header: 'OBOWIĄZEK ORGANIZACJI',
    width: 450,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
    show: isEditorOrSuperAdmin || (isCustomerLogged && (userLevel === USER_LEVELS.DETAILED || userLevel === USER_LEVELS.MANAGING)),
  },
  {
    id: 'regulatory_organ',
    accessor: 'regulatory_organ',
    Header: <Cells.DetailedHeader value="ORGAN NADZORUJĄCY" />,
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <HighlightText text={value} columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'deadline',
    accessor: 'deadline',
    Header: <Cells.DetailedHeader value="TERMIN GRANICZNY" />,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <HighlightText text={value} columnId={column.id} />,
    /* eslint-enable */
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
  },
  {
    id: 'organization_type',
    accessor: 'organization_type',
    Header: 'TYP ORGANIZACJI',
    width: 350,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    // Cell: ({ value, column }) => <HighlightText text={value} columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'released_from_responsibility',
    accessor: 'released_from_responsibility',
    Header: 'ZWOLNIENI Z OBOWIĄZKU',
    width: 350,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'potential_sanctions',
    accessor: 'potential_sanctions',
    Header: <Cells.DetailedHeader value="POTENCJALNE SANKCJE" />,
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'norm_points',
    accessor: 'norm_points',
    Header: <Cells.DetailedHeader value="PUNKT NORMY" />,
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'audit_questions',
    accessor: 'audit_questions',
    Header: <Cells.DetailedHeader value="PYTANIE AUDITORSKIE" />,
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'expected_proofs',
    accessor: 'expected_proofs',
    Header: <Cells.DetailedHeader value="OCZEKIWANY DOWÓD" />,
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'change_description',
    accessor: 'change_description',
    Header: <Cells.DetailedHeader value="OPIS ZMIANY" />,
    width: 350,
    show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
    /* eslint-disable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  // {
  //   id: 'change_date',
  //   // accessor: 'change_date',
  //   accessor: (c) => moment.utc(c.change_date),
  //   Header: <Cells.DetailedHeader value="DATA ZMIAN" />,
  //   filterable: false,
  //   width: 350,
  //   sortMethod: (a, b) => {
  //     if (a && b) {
  //       if (moment(a).format('YYYY-MM-DD').valueOf() < moment(b).format('YYYY-MM-DD').valueOf()) return 1;
  //       else if (moment(a).format('YYYY-MM-DD').valueOf() > moment(b).format('YYYY-MM-DD').valueOf()) return -1;
  //       else return 0;
  //     } else return -1;
  //   },
  //   Cell: ({ original }) => (!original.change_date ? 'Brak' : moment(original.change_date).format('YYYY-MM-DD')),
  //   show: isEditorOrSuperAdmin && !compendiumsActiveCompany,
  // },
  {
    id: 'legal_requirement_owner',
    accessor: (row) => {
      return (
        row.rating &&
        row.rating.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        })
      );
    },
    Header: 'Właściciel wymagania',
    style: { fontWeight: '700' },
    width: 250,
    // headerClassName: `sticky sticky--header sticky--header_last ${isEditorOrSuperAdmin && !compendiumsActiveCompany ? 'sticky--220' : 'sticky--200'}`,
    // className: `sticky ${isEditorOrSuperAdmin && !compendiumsActiveCompany ? 'sticky--220' : 'sticky--200'} sticky--last sticky--cell`,
    /* eslint-disable */
    show: (isCustomerLogged || compendiumsActiveCompany) && !areHiddenRowsShown,
    filterMethod: ({ value, id }, row) => {
      if (value && row[id] && row[id][id]) {
        return `${row[id][id].first_name} ${row[id][id].last_name}`.toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return false;
      }
    },
    // Filter: ({ filter, onChange, column: { id } }) => {
    //   !filter && onChange(filters.hasOwnProperty(id) ? filters[id] : null);
    //   return (
    //     <Select
    //       value={filter ? filter.value : ''}
    //       style={{ width: '100%' }}
    //       placeholder="Wszyscy"
    //       isMulti
    //       onChange={(value) => onChange(value)}
    //       options={usersInCompanyOptions}
    //       classNamePrefix="react-select"
    //     />
    //   );
    // },
    /* eslint-disable */
    Cell: ({ value }) => (
      <>
        {!value?.legal_requirement_owner?.is_online && (
          <>
            <div data-tip={tooltipLastActivity(value?.legal_requirement_owner?.last_activity)} data-for={`tooltipLastActivityxd--${value?.id}`}>
              <HighlightText
                text={
                  value && value?.legal_requirement_owner
                    ? `${value?.legal_requirement_owner?.first_name} ${value?.legal_requirement_owner?.last_name}`
                    : ''
                }
              />
            </div>
            <ReactTooltip
              id={`tooltipLastActivityxd--${value?.id}`}
              getContent={() => tooltipLastActivity(value?.legal_requirement_owner?.last_activity)}
            />
          </>
        )}
        {value?.legal_requirement_owner?.is_online && (
          <>
            <div data-tip={tooltipActiveUser(value?.legal_requirement_owner?.last_activity)} data-for={`tooltipActives--${value?.id}`}>
              <HighlightText
                text={
                  value && value?.legal_requirement_owner
                    ? `${value?.legal_requirement_owner?.first_name} ${value?.legal_requirement_owner?.last_name}`
                    : ''
                }
              />
            </div>
            <ReactTooltip id={`tooltipActives--${value?.id}`} getContent={() => tooltipActiveUser(value?.legal_requirement_owner?.last_activity)} />
          </>
        )}
      </>
    ),
    /* eslint-enable */
  },
  {
    id: 'organizational_unit',
    accessor: (row) => {
      return (
        row.rating &&
        row.rating.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        })
      );
    },
    filterMethod: ({ value, id }, row) => {
      if (value && row[id] && row[id][id]) {
        return row[id][id].toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return false;
      }
    },
    Header: 'Proces/komórka organizacyjna',
    style: { fontWeight: '700' },
    width: 300,
    /* eslint-disable */
    show: (isCustomerLogged || compendiumsActiveCompany) && !areHiddenRowsShown,
    Cell: ({ value }) => <HighlightText text={value?.organizational_unit} />,
    /* eslint-enable */
  },
  {
    id: 'rating',
    accessor: (row) => {
      return (
        row.rating &&
        row.rating.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        })
      );
    },
    Header: 'STATUS ZGODNOŚCI',
    // filterable: false,
    width: 300,
    show: (isCustomerLogged || compendiumsActiveCompany) && !areHiddenRowsShown,
    filterMethod: ({ value, id, label }, row) => {
      filters[id] = value ? { label: value?.searchableLabel || value?.label, value: value?.value } : null;
      saveFiltersInLocal({ ...filters, area });
      if (value && value.value === 'rating_3') {
        if (row[id] && row[id].rating_3) {
          return true;
        } else {
          return false;
        }
      } else if (value && value.value === 'rating_2') {
        if (row[id] && row[id].rating_2) {
          return true;
        } else {
          return false;
        }
      } else if (value && value.value === 'rating_1') {
        if (row[id] && row[id].rating_1) {
          return true;
        } else {
          return false;
        }
      } else if (value && value.value === 'brak') {
        if ((row[id] && !(row[id].rating_1 || row[id].rating_2 || row[id].rating_3)) || !row[id]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    /* eslint-disable */
    Filter: ({ filter, onChange, column: { id } }) => {
      !filter && onChange(filters.hasOwnProperty(id) ? filters[id] : null);
      return (
        <Select
          value={filter && filter.value ? filter.value : ''}
          style={{ width: '100%' }}
          placeholder="Wszystkie"
          isClearable
          onChange={(value) => {
            // console.log(value, 'value');
            if (!value) {
              filters[id] = null;
              saveFiltersInLocal({ ...filters, area });
            }
            onChange(value);
          }}
          options={filterByRating}
          classNamePrefix="react-select"
        />
      );
    },
    /* eslint-disable */
    // Cell: Cells.Rating,
    Cell: ({ original, value }) => {
      const hasIncompleteTasks = value?.hasIncompleteTasks || false;
      if (!value?.work_plan_date) {
        const tasks = value?.tasks?.filter((task) => task.status !== 'done') || [];
        const latestDeadline = getLatestDeadline(tasks);
        const days = moment(latestDeadline).diff(moment(), 'days');
        return (
          <>
            <Cells.Rating original={original} />
            {value?.rating_1 || value?.rating_2 ? (
              hasIncompleteTasks ? (
                <>
                  <p>Plan działań doskonalących w trakcie</p>
                  <p className={`work-plan-date work-plan-date--${days <= 0 ? 'red' : days <= 7 ? 'yellow' : ''}`}>
                    {`Pozostało ${days <= 0 ? 0 : days} dni`}
                  </p>
                  <p className="work-plan-date--text">do {moment(latestDeadline, 'YYYYMMDD').format('YYYY-MM-DD')}</p>
                </>
              ) : (
                <p>Brak planu działań doskonalących</p>
              )
            ) : null}
          </>
        );
      } else if (value?.work_plan_date) {
        const days = moment(value.work_plan_date).diff(moment(), 'days');
        return (
          <>
            <Cells.Rating original={original} />
            <p className={`work-plan-date work-plan-date--${days <= 0 ? 'red' : days <= 7 ? 'yellow' : ''}`}>
              {`Pozostało ${days <= 0 ? 0 : days} dni`}
            </p>
            <p className="work-plan-date--text">do {moment(value.work_plan_date).format('YYYY-MM-DD')}</p>
          </>
        );
      }
    },
    /* eslint-enable */
  },
  {
    id: 'work_plan_date',
    accessor: (row) => {
      return (
        row.rating &&
        row.rating.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        })
      );
    },
    filterMethod: ({ value, id }, row) => {
      if (value && row[id] && row[id][id]) {
        return row[id][id].toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return false;
      }
    },
    Header: 'Plan działań doskonalących',
    style: { fontWeight: '700' },
    width: 250,
    show: false,
    filterable: false,
    /* eslint-disable */
    // show: (isCustomerLogged || compendiumsActiveCompany) && !areHiddenRowsShown,
    Cell: ({ value }) => {
      // return !value?.work_plan_date ? 'Brak' : moment(value.work_plan_date).format('YYYY-MM-DD');

      if (!value?.work_plan_date || value.rating_3) return 'Brak';
      else {
        const days = moment(value.work_plan_date).diff(moment(), 'days');

        return (
          <>
            <p className={`work-plan-date work-plan-date--${days <= 0 ? 'red' : days <= 7 ? 'yellow' : ''}`}>
              {days > 7 ? `${days} dni` : days <= 0 ? `pozostało 0 dni` : `pozostało ${days} dni`}
            </p>
            <p className="work-plan-date--text">do {moment(value.work_plan_date).format('YYYY-MM-DD')}</p>
          </>
        );
      }
    },
    /* eslint-enable */
  },
  {
    id: 'visibility',
    accessor: 'visibility',
    Header: 'WIDOCZNOŚĆ',
    filterable: false,
    width: 150,
    show: isCustomerLogged && userLevel === USER_LEVELS.MANAGING,
    /* eslint-disable */
    Cell: ({ original: { id } }) => (
      <Cells.VisibilityCell
        areHiddenRowsShown={areHiddenRowsShown}
        onClick={async () => {
          await manageRow('compendiums', id);
          getCounters();
        }}
      />
    ),
    /* eslint-enable */
  },
  {
    id: 'created_at',
    accessor: 'created_at',
    Header: 'DATA DODANIA',
    filterable: false,
    width: 200,
    /* eslint-disable */
    Cell: ({ original }) => (!original.created_at ? 'Brak' : moment(original.created_at).format('YYYY-MM-DD')),
    /* eslint-enable */
  },
];

type P = {
  removeFiles?: (uuid: any) => void;
  compendiums: Array<any>;
  history: any;
  onEditRowClick: (compendiumData) => void;
  onDeleteRowClick: (compendiumData) => void;
  onCopyClick: (compendiumData) => void;
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  userLevel: string;
  manageRow: (type, id) => void;
  areHiddenRowsShown: boolean;
  // filterByTerm: any;
  filterByDepartment: any;
  // filterByLegalBasis: any;
  // filterByExecutiveAct: any;
  // filterByReleasedFromResponsibility: any;
  // filterByOrganizationType: any;
  // filterByRegulatoryOrgan: Array<any>;
  filterByRating: any;
  compendiumsActiveCompany: string;
  // usersInCompanyOptions: any;
  isCustomerAreaManager: boolean;
  setColumnFilterTerms: (data: any) => void;
  getCounters: (data: any) => void;
};
class CompendiumsDesktop extends React.Component<P, any> {
  render() {
    const {
      compendiums,
      history,
      onEditRowClick,
      isCustomerLogged,
      isEditorOrSuperAdmin,
      userLevel,
      manageRow,
      areHiddenRowsShown,
      onDeleteRowClick,
      onCopyClick,
      // filterByTerm,
      filterByDepartment,
      // filterByExecutiveAct,
      // filterByLegalBasis,
      // filterByReleasedFromResponsibility,
      // filterByOrganizationType,
      // filterByRegulatoryOrgan,
      filterByRating,
      compendiumsActiveCompany,
      isCustomerAreaManager,
      setColumnFilterTerms,
      getCounters,
      // usersInCompanyOptions,
    } = this.props;
    const area = getArea();
    const tempFilters = getFiltersInLocal();
    const filters = tempFilters.area === area ? tempFilters : {};
    // const filters = [];

    return (
      <div className="compendiumsDesktop">
        <ReactTable
          filterable
          areRowsColored
          data={compendiums}
          showArrows
          columns={getColumns(
            history,
            onEditRowClick,
            isCustomerLogged,
            userLevel,
            manageRow,
            areHiddenRowsShown,
            onDeleteRowClick,
            onCopyClick,
            isEditorOrSuperAdmin,
            // filterByTerm,
            filterByDepartment,
            // filterByExecutiveAct,
            // filterByLegalBasis,
            // filterByReleasedFromResponsibility,
            // filterByOrganizationType,
            // filterByRegulatoryOrgan,
            compendiumsActiveCompany ? true : false,
            area,
            filterByRating,
            // usersInCompanyOptions,
            filters,
            isCustomerAreaManager,
            setColumnFilterTerms,
            getCounters,
          )}
          className="bhpTable bhpTable--compendiums"
        />
      </div>
    );
  }
}

export default CompendiumsDesktop;
