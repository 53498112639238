import * as React from 'react';
import KnowledgePosts from '../knowledgeBase/components/knowledgePosts';
import { MainType } from './Container';
import { Preloader, SVG } from 'Shared/components';
import { REQUEST_STATUS, ROUTES, SVG_TYPE } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';

type S = {
  isEditModalOpened: boolean;
  topicalityData: any;
  type: string;
  page: number;
};

class KnowledgeBaseAll extends React.Component<MainType, S> {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isEditModalOpened: false,
      type: null,
      topicalityData: {
        link: '',
        content: '',
        category: 'bhp',
        bhp: true,
      },
    };
  }

  componentDidMount() {
    this.props.actions.getKnowledgePosts(1);
    this.props.actions.getTopicalities(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editingTopicality.status === REQUEST_STATUS.INIT && this.props.editingTopicality.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isEditModalOpened: false });
    }
  }

  onEditRowClick = (topicalityData) => {
    topicalityData[topicalityData.category] = true;
    this.setState({ isEditModalOpened: true, type: 'EDIT', topicalityData });
  };

  onDeleteRowClick = (topicalityData) => {
    this.setState({ isEditModalOpened: true, type: 'DELETE', topicalityData });
  };

  onModalClose = () => this.setState({ isEditModalOpened: false });
  onModalOpen = () => this.setState({ type: 'ADD', isEditModalOpened: true });
  isOpened = () => this.state.isEditModalOpened;
  isEditLoading = () => this.props.editingTopicality.status === REQUEST_STATUS.INIT;
  onSubmit = (data) => {
    const { type } = this.state;
    type === 'ADD' ? this.props.actions.addTopicality(data) : this.props.actions.editTopicality(data);
    type === 'ADD' && this.setState({ isEditModalOpened: false });
  };
  onDelete = () => {
    const {
      topicalityData: { uuid },
    } = this.state;
    this.props.actions.deleteTopicality(uuid);
    this.setState({ isEditModalOpened: false });
  };
  render() {
    const { knowledgePosts, knowledgePostsStatus } = this.props;

    const handleGoBack = () => {
      history.push({ pathname: ROUTES.BAZA_WIEDZY });
    };

    return (
      <div className="knowledgeBase knowledgeBase--news">
        <div className="knowledgeBase--l">
          <div className="knowledgeBase__title">
            <h1 className="title">Baza Wiedzy</h1>
            <div className="goBack" onClick={() => handleGoBack()}>
              <SVG type={SVG_TYPE.CHEVRON_SECOND} /> Wróć
            </div>
          </div>
          {(knowledgePostsStatus === REQUEST_STATUS.INIT || knowledgePostsStatus === REQUEST_STATUS.IDLE) && this.state.page === 1 ? (
            <Preloader inner knowledgeBase />
          ) : (
            <KnowledgePosts getKnowledgePosts={this.props.actions.getKnowledgePosts} postLimit={0} knowledgePosts={knowledgePosts} />
          )}
        </div>
      </div>
    );
  }
}

export default KnowledgeBaseAll;
