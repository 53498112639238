import API, { WpApi } from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function getKnowledgePosts(page = 1): AxiosPromise<any> {
  return WpApi.get(`posts?_embed&page=${page}`);
}

export function getTopicalities(page?): AxiosPromise<any> {
  return API.get(`topicalities${page ? '?page=' + page : ''}`);
}

export function addTopicality(topicalityData): AxiosPromise<any> {
  return API.post('topicalities', topicalityData);
}

export function editTopicality(topicality: any, id: number): AxiosPromise<any> {
  return API.put(`topicalities/${id}`, { ...topicality });
}

export function deleteTopicality(uuid: string): AxiosPromise<any> {
  return API.delete(`topicalities/${uuid}`);
}
