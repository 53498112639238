import * as React from 'react';
import KnowledgePosts from './components/knowledgePosts';
import TopicalityForm from './components/topicalityForm';
import CurrentNews from './components/currentNews';
import { MainType } from './Container';
import { Preloader, Modal } from 'Shared/components';
import { REQUEST_STATUS, ROLES, MODAL_TYPE, ROUTES } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';

type S = {
  isEditModalOpened: boolean;
  topicalityData: any;
  type: string;
};

class KnowledgeBase extends React.Component<MainType, S> {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpened: false,
      type: null,
      topicalityData: {
        link: '',
        content: '',
        category: 'bhp',
        bhp: true,
      },
    };
  }

  componentDidMount() {
    this.props.actions.getKnowledgePosts(1);
    this.props.actions.getTopicalities(1);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editingTopicality.status === REQUEST_STATUS.INIT && this.props.editingTopicality.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isEditModalOpened: false });
    }
  }

  onEditRowClick = (topicalityData) => {
    topicalityData[topicalityData.category] = true;
    this.setState({ isEditModalOpened: true, type: 'EDIT', topicalityData });
  };

  onDeleteRowClick = (topicalityData) => {
    this.setState({ isEditModalOpened: true, type: 'DELETE', topicalityData });
  };

  onModalClose = () => this.setState({ isEditModalOpened: false });
  onModalOpen = () => this.setState({ type: 'ADD', isEditModalOpened: true });
  isOpened = () => this.state.isEditModalOpened;
  isEditLoading = () => this.props.editingTopicality.status === REQUEST_STATUS.INIT;
  onSubmit = (data) => {
    const { type } = this.state;
    type === 'ADD' ? this.props.actions.addTopicality(data) : this.props.actions.editTopicality(data);
    type === 'ADD' && this.setState({ isEditModalOpened: false });
  };
  onDelete = async () => {
    const {
      topicalityData: { uuid },
    } = this.state;
    await this.props.actions.deleteTopicality(uuid);
    await this.props.actions.getTopicalities(1);
    this.setState({ isEditModalOpened: false });
  };
  render() {
    const {
      knowledgePosts,
      topicalities,
      knowledgePostsStatus,
      authorizedUser: { roles },
      actions: { getTopicalities },
    } = this.props;
    const { topicalityData, type } = this.state;
    const { FORM, DELETE } = MODAL_TYPE;
    const isTopicalitiesEditable = roles.some(({ role }) => role === ROLES.SUPER_ADMIN || role === ROLES.EDITOR);

    const handleClickShowAll = (routes: string) => {
      history.push({ pathname: routes });
    };

    return (
      <div className="knowledgeBase">
        <div className="knowledgeBase--l">
          <div className="knowledgeBase__title">
            <h1 className="title">Baza Wiedzy</h1>
            <div>
              <p onClick={() => handleClickShowAll(ROUTES.BAZA_WIEDZY_ALL)}>Zobacz wszystkie</p>
            </div>
          </div>
          {knowledgePostsStatus === REQUEST_STATUS.INIT || knowledgePostsStatus === REQUEST_STATUS.IDLE ? (
            <Preloader inner knowledgeBase />
          ) : (
            <KnowledgePosts postLimit={4} knowledgePosts={knowledgePosts} />
          )}
        </div>
        <div className="knowledgeBase--r">
          <div className="knowledgeBase__title">
            <h2 className="title">Aktualności</h2>
            <div className="actions">
              <p onClick={() => handleClickShowAll(ROUTES.AKTUALNOSCI)}>Zobacz wszystkie</p>
              {isTopicalitiesEditable && (
                <button className="knowledgeBase__addTopicality" onClick={this.onModalOpen}>
                  Dodaj +
                </button>
              )}
            </div>
          </div>
          <CurrentNews
            showCoupled
            topicalities={topicalities}
            onEditRowClick={this.onEditRowClick}
            onDeleteRowClick={this.onDeleteRowClick}
            isTopicalitiesEditable={isTopicalitiesEditable}
            getTopicalities={getTopicalities}
          />
        </div>
        <Modal
          type={type === 'DELETE' ? DELETE : FORM}
          opened={this.isOpened()}
          onSubmit={this.onDelete}
          onClose={this.onModalClose}
          big={type !== 'DELETE'}>
          {type !== 'DELETE' ? (
            <TopicalityForm
              type={this.state.type}
              isLoading={this.isEditLoading()}
              onClose={this.onModalClose}
              initialValues={topicalityData}
              onSubmit={this.onSubmit}
            />
          ) : (
            <>
              <div className="modal__title">Usuń aktualność</div>
              <div className="modal__info">
                <ul>
                  <li>
                    Treść: <span className="info-value">{topicalityData.content}</span>
                  </li>
                  <li>
                    Link:
                    <span className="info-value">{topicalityData.link}</span>
                  </li>
                </ul>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

export default KnowledgeBase;
