import * as React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Selectors as VMSelectors, Actions as VMActions } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors, Actions as AuthActions, Types as AuthTypes } from 'Modules/AuthModule';
import { Selectors as NotificationSelectors, Actions as NotificationActions } from 'Modules/NotificationModule';
import { Selectors as NewTabSelectors, Actions as NewTabActions } from 'Modules/NewTabModule';
import { RootState } from 'Store/Reducers/RootReducer';

import AreaDashboard from 'Screens/bhp/Container';
import AreaManagement from 'Screens/areaManagement/Container';
import KnowledgeBase from 'Screens/knowledgeBase/Container';
import News from 'Screens/news/Container';
import KnowledgeBaseAll from 'Screens/knowledgeBaseAll/Container';
import Changelog from 'Screens/changelog/Container';
import Settings from 'Screens/settings/Container';
import Notifications from 'Screens/notifications/Container';
import Users from 'Screens/users/Container';
import UserManagement from 'Screens/userManagement/Container';
import CompendiumSingle from 'Screens/compendiumSingle/Container';

import { ROUTES } from 'Shared/enums';
import { RoleRoute, DashboardSidebar, DashboardHeader } from 'Shared/components';

type S = {};

class Dashboard extends React.Component<MainType, S> {
  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  public render() {
    const {
      authorizedUser,
      logout,
      getLastNotifications,
      readNotification,
      lastNotifications,
      getNotificationsNotRead,
      notificationsNotRead,
      getNewTab,
      createNewTab,
      editNewTab,
      newTab,
    } = this.props;
    if (authorizedUser) {
      return (
        <div className="dashboard">
          <DashboardSidebar authorizedUser={authorizedUser} logout={logout} />
          <div className={`dashboard__content ${this.props.isMenuExpanded ? 'is-blurred' : ''}`}>
            <DashboardHeader
              getNewTab={getNewTab}
              createNewTab={createNewTab}
              editNewTab={editNewTab}
              newTab={newTab}
              getNotificationsNotRead={getNotificationsNotRead}
              notificationsNotRead={notificationsNotRead}
              readNotification={readNotification}
              lastNotifications={lastNotifications}
              authorizedUser={authorizedUser}
              getLastNotifications={getLastNotifications}
            />
            <div className="dashboard__page">
              <Switch>
                <RoleRoute exact path={ROUTES.USERS} component={Users} authorizedUser={authorizedUser} />
                <RoleRoute exact path={`${ROUTES.USERS}/:id`} component={UserManagement} authorizedUser={authorizedUser} />
                <RoleRoute exact key={ROUTES.BHP} path={ROUTES.BHP} component={AreaDashboard} authorizedUser={authorizedUser} />
                <RoleRoute exact path={`${ROUTES.BHP}/dodaj/:id`} component={AreaManagement} authorizedUser={authorizedUser} />
                <RoleRoute exact path={`${ROUTES.ENERGIA}/dodaj/:id`} component={AreaManagement} authorizedUser={authorizedUser} />
                <RoleRoute exact path={`${ROUTES.ENVIRONMENT_PROTECTION}/dodaj/:id`} component={AreaManagement} authorizedUser={authorizedUser} />
                <RoleRoute exact path={`${ROUTES.ADDITIONAL}/dodaj/:id`} component={AreaManagement} authorizedUser={authorizedUser} />
                <RoleRoute
                  exact
                  key={ROUTES.ENVIRONMENT_PROTECTION}
                  path={ROUTES.ENVIRONMENT_PROTECTION}
                  component={AreaDashboard}
                  authorizedUser={authorizedUser}
                />
                <RoleRoute exact key={ROUTES.ADDITIONAL} path={ROUTES.ADDITIONAL} component={AreaDashboard} authorizedUser={authorizedUser} />
                <RoleRoute exact key={ROUTES.ENERGIA} path={ROUTES.ENERGIA} component={AreaDashboard} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.BAZA_WIEDZY} component={KnowledgeBase} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.AKTUALNOSCI} component={News} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.BAZA_WIEDZY_ALL} component={KnowledgeBaseAll} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.COMPENDIUM_DETAILS} component={CompendiumSingle} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.CHANGELOG} component={Changelog} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.SETTINGS} component={Settings} authorizedUser={authorizedUser} />
                <RoleRoute exact path={ROUTES.NOTIFICATIONS} component={Notifications} authorizedUser={authorizedUser} />
              </Switch>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

type ConnectedP = {
  authorizedUser: AuthTypes.AuthorizedUserData;
  notificationsNotRead: boolean;
  isMenuExpanded: boolean;
  token: string;
  lastNotifications: any;
  newTab: any;
};
type DispatchedP = {
  logout: () => void;
  getAuthorizedUser: () => void;
  getLastNotifications: () => void;
  getNotificationsNotRead: () => void;
  readNotification: (data) => void;
  createNewTab: (data) => void;
  editNewTab: (data) => void;
  getNewTab: () => void;
  expandMenu: () => void;
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthActions.logout(),
  getAuthorizedUser: () => AuthActions.getAuthorizedUser(),
  expandMenu: () => VMActions.expandMenu(),
  getLastNotifications: () => NotificationActions.getLastNotifications(),
  getNotificationsNotRead: () => NotificationActions.getNotificationsNotRead(),
  readNotification: (data) => NotificationActions.readNotification(data),
  getNewTab: () => NewTabActions.getNewTab(),
  createNewTab: (data) => NewTabActions.createNewTab(data),
  editNewTab: (data) => NewTabActions.editNewTab(data),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    notificationsNotRead: NotificationSelectors.getNotificationsNotRead(state),
    lastNotifications: NotificationSelectors.getLastNotifications(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
    isMenuExpanded: VMSelectors.isMenuExpanded(state),
    newTab: NewTabSelectors.getNewTab(state),
    token: state.authStore.token,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
