import { Connector } from 'Modules/KnowledgeModule';
import { Knowledge } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Knowledge.GET_TOPICALITIES_INIT,
});

const success = (topicalities: any) => ({
  type: Knowledge.GET_TOPICALITIES_SUCCESS,
  payload: {
    topicalities,
  },
});

const failure = () => ({
  type: Knowledge.GET_TOPICALITIES_FAILURE,
});

const getTopicalities = (page?) => {

  return async (dispatch) => {
    try {
      if(page === 1){dispatch(init())}
      const { data: topicalities } = await Connector.getTopicalities(page);
      dispatch(success(topicalities));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default getTopicalities;
