import React, { useEffect, useState } from 'react';
import image from 'Assets/Img/knowledge_post_1.png';
import moment from 'moment';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { useDispatch } from 'react-redux';
type P = {
  knowledgePosts: Array<any>;
  postLimit?: any;
  getKnowledgePosts?: any;
};

const KnowledgePosts = ({ knowledgePosts, postLimit, getKnowledgePosts }: P) => {
  const dispatch = useDispatch();
  const limitedPosts = postLimit ? knowledgePosts.slice(0, postLimit) : knowledgePosts;
  const isInfinite = postLimit === 0;
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const dashboardContent = document.querySelector('.dashboard__content');
      if (!dashboardContent) return;

      const { scrollTop, scrollHeight, clientHeight } = dashboardContent;

      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setPage((prevPage) => prevPage + 1);
        setIsLoading(true);
      }
    };

    if (getKnowledgePosts && isInfinite) {
      const dashboardContent = document.querySelector('.dashboard__content');
      if (dashboardContent) {
        dashboardContent.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (dashboardContent) {
          dashboardContent.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [getKnowledgePosts, isInfinite, postLimit]);

  useEffect(() => {
    const fetchPosts = async () => {
      if (getKnowledgePosts && isInfinite && page !== 1 && isLoading && !isEnd) {
        setIsLoading(false);
        const response = await getKnowledgePosts(page);
        if (response.length === 0) {
          setIsEnd(true);
        } else {
          setIsLoading(false);
        }
      }
    };

    fetchPosts();
  }, [page, getKnowledgePosts, isInfinite, isLoading, isEnd, dispatch]);

  if (!isInfinite) {
    return (
      <div className="knowledgePosts">
        <div className="knowledgePosts__inner">
          {limitedPosts.map((post) => {
            return <Post isInfinite={isInfinite} key={post.id} post={post} />;
          })}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="knowledgePostsTop">
          <div className="knowledgePostsTop__left">
            {limitedPosts.slice(0, 1).map((post, index) => {
              const first = index === 0;
              if (first) {
                return (
                  <div className="knowledgePostsTop__first" key={post.id}>
                    <Post big content={first ? post?.content : null} post={post} />
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="knowledgePostsTop__right">
            {limitedPosts.slice(1, 4).map((post, index) => {
              const isNotFirst = index > 0;
              return (
                <div key={index} className={`knowledgePost ${isNotFirst ? 'knowledgePost--infinite' : ''}`}>
                  <a className="knowledgePost__vertical" href={post.link} target="_blank" rel="noopener noreferrer">
                    <div className="knowledgePost__left">
                      <div className="knowledgePost__image">
                        <img src={post?.image || image} alt={post.title} />
                      </div>
                    </div>
                    <div className="knowledgePost__right knowledgePost__vertical--right">
                      <div className="knowledgePost__date">{moment(post.createDate).format('D MMMM YYYY')}</div>
                      <div className="knowledgePost__title">{post.title.length > 80 ? `${post.title.substring(0, 80)}...` : post.title}</div>
                      <div className="knowledgePost__readMore">
                        Czytaj więcej
                        <SVG type={SVG_TYPE.ARROW_SLASH} />
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="knowledgePosts">
          <div className="knowledgePosts__inner">
            {limitedPosts.length > 4 &&
              limitedPosts.slice(4).map((post: any, index: number) => {
                return <Post isInfinite={true} key={index} post={post} />;
              })}
          </div>
        </div>
      </>
    );
  }
};

export default KnowledgePosts;

const Post = ({ post, isInfinite, content, big }: any) => {
  const stripHtml = (html) =>
    html &&
    html
      .replace(/<[^>]+>/g, '') // Usuwa znaczniki HTML
      .replace(/&#8230;/g, '...');
  const plainTextContent = stripHtml(content);
  return (
    <div className={`knowledgePost ${isInfinite ? 'knowledgePost--infinite' : ''}`}>
      <a href={post.link} target="_blank" rel="noopener noreferrer">
        <div className="knowledgePost__image">
          <img className={`${big ? 'knowledgePost__image--big' : ''}`} src={post?.image || image} alt={post.title} />
        </div>
        <div className="knowledgePost__date">{moment(post.createDate).format('D MMMM YYYY')}</div>
        <div className="knowledgePost__title">{post.title.length > 155 ? `${post.title.substring(0, 155)}...` : post.title}</div>
        {content && (
          <div className="knowledgePost__content">{plainTextContent > 400 ? `${plainTextContent.substring(0, 400)}...` : plainTextContent}</div>
        )}
        <div className="knowledgePost__readMore">
          Czytaj więcej
          <SVG type={SVG_TYPE.ARROW_SLASH} />
        </div>
      </a>
    </div>
  );
};
