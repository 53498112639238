import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField, ColorPicker } from 'Shared/components/forms';
import { Button, SVG, Modal } from 'Shared/components';
import { MODAL_TYPE } from 'Shared/enums';
import LabelForm from 'Screens/areaManagement/components/labelForm';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  isLoading: boolean;
  onClose: () => void;
  initialValues: any;
  onSubmit: (formValues: any) => any;
  isCustomerAreaManager: boolean;
  removeFiles?: (uuid: any) => void;
  setInitialValues?: (any) => void;
};

const CompendiumForm = ({ setInitialValues, onClose, initialValues, onSubmit, isLoading, isCustomerAreaManager, removeFiles }: P) => {
  // const [isBasisSource, setBasisSource] = React.useState(initialValues.legal_basis_file_name);
  // const [isActSource, setActSource] = React.useState(initialValues.executive_act_file_name);
  const [isModalPdfDeleteOpen, setIsModalPdfDeleteOpen] = React.useState({ isOpened: false, uuid: '' });
  const [legalBasisPdfs, setLegalBasisPdfs] = React.useState([]);
  const [executiveActPdfs, setExecutiveActPdfs] = React.useState([]);

  React.useEffect(() => {
    if (initialValues.pdfs) {
      setLegalBasisPdfs(initialValues.pdfs.filter(({ type }) => type === 'legal_basis'));
      setExecutiveActPdfs(initialValues.pdfs.filter(({ type }) => type === 'executive_act'));
    }
  }, [initialValues]);

  // console.log(legalBasisPdfs);
  // console.log(executiveActPdfs);

  // let legal_basis_pdf;
  // // let executive_act_pdf;
  // if (initialValues.pdfs) {
  //   legal_basis_pdf = initialValues.pdfs.find(({ type }) => type === 'legal_basis');
  //   executive_act_pdf = initialValues.pdfs.find(({ type }) => type === 'executive_act');
  // }

  const handleClickDeletePdf = (uuid) => {
    setIsModalPdfDeleteOpen({ isOpened: true, uuid: uuid });
  };
  const handleRemoveFile = async () => {
    await removeFiles(isModalPdfDeleteOpen.uuid);
    const pdfs = initialValues.pdfs.filter(({ uuid }) => uuid !== isModalPdfDeleteOpen.uuid);
    setIsModalPdfDeleteOpen({ isOpened: false, uuid: '' });
    setInitialValues(pdfs);
  };

  return (
    // <Modal type={MODAL_TYPE.FORM} opened={opened} big>
    <>
      <Modal
        type={MODAL_TYPE.DELETE}
        opened={isModalPdfDeleteOpen.isOpened}
        onSubmit={() => handleRemoveFile()}
        onClose={() => setIsModalPdfDeleteOpen({ isOpened: false, uuid: null })}
        big>
        <>
          <div className="modal__title">Potwierdź usunięcie pliku</div>
        </>
      </Modal>
      <div className="editModal">
        <h1 className="font-lg bold-700">Edytuj kompendium</h1>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, errors }) => {
            return (
              <form onSubmit={handleSubmit} className="editModal--form">
                <LabelForm value="Dział" />
                <Field type="text" name="department" component={RegularField} errors={errors} />
                <LabelForm value="Pojęcie" />
                <Field type="text" name="term" component={RegularField} errors={errors} />
                <LabelForm value="Główna podstawa prawna" source />
                {/* <p className="editModal--file-name">{initialValues.legal_basis_file_name}</p> */}
                {legalBasisPdfs &&
                  legalBasisPdfs.length > 0 &&
                  legalBasisPdfs.map((item) => {
                    return (
                      <div key={item.uuid} className="pdf-file">
                        <div className="pdf-file__item">
                          <a
                            className="editModal--file-name"
                            href={`${process.env.REACT_APP_API_URL}pdfs/${item?.uuid}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {item.file_name}
                          </a>
                          <div className="pdf-file--delete" onClick={() => handleClickDeletePdf(item.uuid)}>
                            <SVG type={SVG_TYPE.DELETE} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {legalBasisPdfs && legalBasisPdfs.length === 0 && (
                  <>
                    <Field type="file" name="legal_basis_source1" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                    <Field type="file" name="legal_basis_source2" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                    <Field type="file" name="legal_basis_source3" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                  </>
                )}
                {legalBasisPdfs && legalBasisPdfs.length === 1 && (
                  <>
                    <Field type="file" name="legal_basis_source2" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                    <Field type="file" name="legal_basis_source3" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                  </>
                )}
                {legalBasisPdfs && legalBasisPdfs.length === 2 && (
                  <>
                    <Field type="file" name="legal_basis_source3" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                  </>
                )}
                {/* <Field placeholder="dodaj tekst..." type="textarea" name="legal_basis" component={RegularField} errors={errors} /> */}
                <Field type="textarea" name="legal_basis" component={RegularField} errors={errors} />
                <LabelForm value="Akt wykonawczy" source />
                {/* <p className="editModal--file-name">{initialValues.executive_act_file_name}</p> */}
                {executiveActPdfs &&
                  executiveActPdfs.length > 0 &&
                  executiveActPdfs.map((item) => {
                    return (
                      <div key={item.uuid} className="pdf-file">
                        <div className="pdf-file__item">
                          <a
                            className="editModal--file-name"
                            href={`${process.env.REACT_APP_API_URL}pdfs/${item?.uuid}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {item.file_name}
                          </a>
                          <div className="pdf-file--delete" onClick={() => handleClickDeletePdf(item.uuid)}>
                            <SVG type={SVG_TYPE.DELETE} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {executiveActPdfs && executiveActPdfs.length === 0 && (
                  <>
                    <Field type="file" name="executive_act_source1" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                    <Field type="file" name="executive_act_source2" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                    <Field type="file" name="executive_act_source3" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                  </>
                )}
                {executiveActPdfs && executiveActPdfs.length === 1 && (
                  <>
                    <Field type="file" name="executive_act_source2" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                    <Field type="file" name="executive_act_source3" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                  </>
                )}
                {executiveActPdfs && executiveActPdfs.length === 2 && (
                  <>
                    <Field type="file" name="executive_act_source3" placeholder="dodaj pdf..." component={RegularField} errors={errors} />
                  </>
                )}
                {/* {isActSource && <Field type="file" name="executive_act_source" placeholder="dodaj pdf..." component={RegularField} errors={errors} />} */}

                <Field type="textarea" name="executive_act" component={RegularField} errors={errors} />
                <LabelForm value="Wymaganie prawne" />
                <Field type="textarea" name="legal_requirements" component={RegularField} errors={errors} />
                <LabelForm value="Obowiązek organizacji" starred />
                <Field type="textarea" name="organization_responsibilities" component={RegularField} errors={errors} />
                <LabelForm value="Organ nadzorujący" starred />
                <Field type="textarea" name="regulatory_organ" component={RegularField} errors={errors} />
                <LabelForm value="Termin graniczny" starred />
                <Field type="textarea" name="deadline" component={RegularField} errors={errors} />
                <LabelForm value="Typ organizacji" />
                <Field type="text" name="organization_type" component={RegularField} errors={errors} />
                <LabelForm value="Zwolnieni z obowiązku" />
                <Field type="text" name="released_from_responsibility" component={RegularField} errors={errors} />
                <LabelForm value="Potencjalne sankcje" starred />
                <Field type="textarea" name="potential_sanctions" component={RegularField} errors={errors} />
                <LabelForm value="Punkt normy" starred />
                <Field type="textarea" name="norm_points" component={RegularField} errors={errors} />
                <LabelForm value="Pytanie auditorskie" starred />
                <Field type="textarea" name="audit_questions" component={RegularField} errors={errors} />
                <LabelForm value="Oczekiwany dowód" starred />
                <Field type="textarea" name="expected_proofs" component={RegularField} errors={errors} />
                <LabelForm value="Opis zmiany" starred />
                <Field type="textarea" name="change_description" component={RegularField} errors={errors} />
                {/* <Field type="textarea" name="change_date" label="Data zmian" component={RegularField} errors={errors} /> */}
                {/* <LabelForm value="Data zmian" starred />
            <Field name="change_date" type="text" component={DatePicker} errors={errors} /> */}
                {isCustomerAreaManager ? (
                  <>
                    <LabelForm value="Kolor wpisu" />
                    <Field name="color" type="text" component={ColorPicker} errors={errors} />
                  </>
                ) : (
                  <>
                    <LabelForm value="Widoczność" />
                    <Field name="visible_for_demo" type="checkbox" label="Widoczny dla konta demo" component={RegularField} errors={errors} />
                  </>
                )}
                <div className="editModal__actions">
                  <Button label="Anuluj" classNames="btn btn--transparent btn--cancel" onClick={onClose} />
                  <Button label="Zapisz zmiany" classNames="btn btn--navy-blue" type="submit" isLoading={isLoading} />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
    // </Modal>
  );
};

export default CompendiumForm;
