import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField, Badge, TableSelect } from 'Shared/components/forms';
import * as Yup from 'yup';

type P = {
  addUser: (userData: any) => void;
  options: Array<any>;
  disabled: boolean;
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Pole wymagane'),
  first_name: Yup.string().required('Pole wymagane'),
  last_name: Yup.string().required('Pole wymagane'),
});

const AssociateUserRow = ({ addUser, options, disabled }: P) => {
  return (
    <div className="associateUserRow">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: undefined,
          first_name: undefined,
          last_name: undefined,
          area_bhp: false,
          area_energy: false,
          area_environment: false,
          area_additional: false,
          level: options[0].value,
          role: ['customer'],
        }}
        onSubmit={addUser}
        enableReinitialize>
        {({ handleSubmit, errors, values }) => {
          
          return (
            <form onSubmit={handleSubmit} className="manageUser__form">
              <div className="associateUserRow__inner">
                <div className="associateUserRow--l">
                  <Field name="email" type="text" placeholder={'E-mail'} component={RegularField} errors={errors} wrapperClassName={'email'} />
                  <Field
                    name="first_name"
                    type="text"
                    placeholder={'Imię'}
                    component={RegularField}
                    errors={errors}
                    wrapperClassName={'first_name'}
                  />
                  <Field
                    name="last_name"
                    type="text"
                    placeholder={'Nazwisko'}
                    component={RegularField}
                    errors={errors}
                    wrapperClassName={'last_name'}
                  />
                </div>
                <div className="associateUserRow--r">
                  <div className="associateUserRow__permissions">
                    <Field label="BHP" name="area_bhp" component={Badge} errors={errors} color="blue" />
                    <Field label="OŚ" name="area_environment" component={Badge} errors={errors} color="green" />
                    <Field label="EN" name="area_energy" component={Badge} errors={errors} color="orange" />
                    <Field label="DOD" name="area_additional" component={Badge} errors={errors} color="red" />
                    <Field name="level" options={options} component={TableSelect} errors={errors} wrapperClassName={'level'} />
                  </div>
                  <button type="submit" className={`btn btn--small btn--navy-blue ${disabled ? 'disabled' : ''}`} disabled={disabled}>
                    Dodaj
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AssociateUserRow;
