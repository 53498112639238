import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import KnowledgeBase from './Main';
import { Actions, Selectors } from 'Modules/KnowledgeModule';
import { REQUEST_STATUS } from 'Shared/enums';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';

type ConnectedP = {
  topicalities: {
    lastPage: number;
    page: number;
    data: Array<any>;
  };
  knowledgePosts: Array<any>;
  knowledgePostsStatus: REQUEST_STATUS;
  authorizedUser: any;
  editingTopicality: {
    status: REQUEST_STATUS;
    uuid: number;
  };
};

type DispatchedP = {
  actions: {
    getKnowledgePosts: (page: number) => void;
    getTopicalities: (page?) => void;
    addTopicality: (topicalityData: any) => void;
    editTopicality: (topicalityData: any) => void;
    deleteTopicality: (topicalityUuid: any) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getKnowledgePosts: Actions.getKnowledgePosts,
      getTopicalities: Actions.getTopicalities,
      addTopicality: Actions.addTopicality,
      editTopicality: Actions.editTopicality,
      deleteTopicality: Actions.deleteTopicality,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  knowledgePosts: Selectors.getKnowledgePosts(state),
  topicalities: Selectors.getTopicalities(state),
  knowledgePostsStatus: Selectors.getKnowledgePostsStatus(state),
  authorizedUser: AuthSelectors.authorizedUser(state),
  editingTopicality: Selectors.getEditingTopicalityStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase);
