import * as Types from 'Shared/models/KnowledgeModel';
import { Knowledge } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.KnowledgeStore;

export const initialState: State = {
  knowledgePosts: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  topicalities: {
    data: {
      lastPage: null,
      page: null,
      data: [],
    },
    status: REQUEST_STATUS.IDLE,
  },
  editingTopicality: {
    status: REQUEST_STATUS.IDLE,
    uuid: undefined,
  },
  deletingTopicality: {
    status: REQUEST_STATUS.IDLE,
    uuid: undefined,
  },
};

export function knowledgeReducer(state: State = initialState, action) {
  switch (action.type) {
    case Knowledge.GET_KNOWLEDGE_POSTS_SUCCESS: {
      return {
        ...state,
        knowledgePosts: {
          data: [...state.knowledgePosts.data, ...action.payload.knowledgePosts],
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Knowledge.GET_KNOWLEDGE_POSTS_INIT: {
      return {
        ...state,
        knowledgePosts: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Knowledge.GET_KNOWLEDGE_POSTS_FAILURE: {
      return {
        ...state,
        knowledgePosts: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Knowledge.GET_TOPICALITIES_INIT: {
      return {
        ...state,
        topicalities: {
          data: {
            lastPage: null,
            page: null,
            data: [],
          },
          status: REQUEST_STATUS.IDLE,
        },
      };
    }
    case Knowledge.GET_TOPICALITIES_SUCCESS: {
      return {
        ...state,
        topicalities: {
          ...state.topicalities,
          data: {
            ...state.topicalities.data,
            data: [...state.topicalities.data.data, ...action.payload.topicalities.data],
            page: action.payload.topicalities.page,
            lastPage: action.payload.topicalities.lastPage,
          },
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Knowledge.GET_TOPICALITIES_FAILURE: {
      return {
        ...state,
        topicalities: {
          data: {},
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Knowledge.EDIT_TOPICALITY_INIT: {
      return {
        ...state,
        editingTopicality: {
          status: REQUEST_STATUS.INIT,
          uuid: action.payload.uuid,
        },
      };
    }
    case Knowledge.EDIT_TOPICALITY_SUCCESS: {
      const {
        data: { data: arrayData },
      } = state.topicalities;
      const idx = arrayData.findIndex(({ uuid }) => uuid === action.payload.topicality.uuid);
      arrayData[idx] = action.payload.topicality;
      return {
        ...state,
        topicalities: {
          ...state.topicalities,
          arrayData,
        },
        editingTopicality: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Knowledge.EDIT_TOPICALITY_FAILURE: {
      return {
        ...state,
        editingTopicality: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Knowledge.DELETE_TOPICALITY_INIT: {
      return {
        ...state,
        deletingTopicality: {
          status: REQUEST_STATUS.INIT,
          uuid: action.payload.uuid,
        },
      };
    }
    case Knowledge.DELETE_TOPICALITY_SUCCESS: {
      const {
        data: { data: arrayData },
      } = state.topicalities;
      const idx = arrayData.findIndex(({ uuid }) => uuid === action.payload.uuid);
      arrayData.splice(idx, 1);
      return {
        ...state,
        topicalities: {
          ...state.topicalities,
          arrayData,
        },
        deletingTopicality: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Knowledge.DELETE_TOPICALITY_FAILURE: {
      return {
        ...state,
        deletingTopicality: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default: {
      return state;
    }
  }
}
