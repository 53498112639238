enum SVG_TYPE {
  FILE = 'FILE',
  FILE_ADD = 'FILE_ADD',
  FILE_REMOVE = 'FILE_REMOVE',
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',
  PARAGRAPH = 'PARAGRAPH',
  BHP = 'BHP',
  ENV_PROTECTION = 'ENV_PROTECTION',
  ENERGY = 'ENERGY',
  KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
  USERS = 'USERS',
  DELETE = 'DELETE',
  CALENDAR = 'CALENDAR',
  ALERT = 'ALERT',
  STAR = 'STAR',
  EDIT = 'EDIT',
  SOURCE = 'SOURCE',
  PDF = 'PDF',
  ROW_DELETE = 'ROW_DELETE',
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
  // BLUE_FACE = 'BLUE_FACE',
  GREEN_FACE = 'GREEN_FACE',
  // ORANGE_FACE = 'ORANGE_FACE',
  // ORANGE_FACE = 'ORANGE_FACE',
  // RED_FACE = 'RED_FACE',
  USER_TIE = 'USER_TIE',
  RATING_CALENDAR = 'RATING_CALENDAR',
  STAR_FILLED = 'STAR_FILLED',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  CHEVRON = 'CHEVRON',
  MAGNIFIER = 'MAGNIFIER',
  GREY_FACE = 'GREY_FACE',
  CLOSE = 'CLOSE',
  USER = 'USER',
  FILE_ACTIVE = 'FILE_ACTIVE',
  CHAT = 'CHAT',
  COPY = 'COPY',
  NOTIFICATION = 'NOTIFICATION',
  NOTIFICATION_DOT = 'NOTIFICATION_DOT',
  NOTIFICATION_OPEN = 'NOTIFICATION_OPEN',
  ARROW_SLASH = 'ARROW_SLASH',
  CHEVRON_SECOND = 'CHEVRON_SECOND',
  ARROW = 'ARROW',
  EXCLAMATION = 'EXCLAMATION',
}

export default SVG_TYPE;
